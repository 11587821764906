import * as React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  styled,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment-timezone'
import { faComment } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const BodyTab = styled(Tab)(({ theme }) => ({
  color: lightTheme.palette.grey.main,
  fontFamily: 'Century Gothic Bold',
  fontSize: 12,
  letterSpacing: '0.2px',
  lineHeight: '140%',

  '&.Mui-selected': {
    background: lightTheme.palette.primary.b6,
    color: lightTheme.palette.primary.main,
  },
}))

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  minHeight: 280,
  maxHeight: 400,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const IconBox = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 auto',

  '&:hover': {
    borderWidth: '2px',
  },
  '&:active': {
    borderWidth: '1px',
  },
}))

export default function BiBlockTable({
  rdvAVenir,
  rdvPasse,
  heureAVenir,
  handleOpenEvent,
  type,
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar position="static" sx={{ boxShadow: 'none' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="none"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{
            backgroundColor: lightTheme.palette.grey.light,
          }}
        >
          <BodyTab label={t('profile.comming')} {...a11yProps(0)} />
          <BodyTab label={t('profile.passed')} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        sx={{
          '&::-webkit-scrollbar': {
            width: 10,
          },
          '&::-webkit-scrollbar-track': {
            background: lightTheme.palette.primary.b5,
          },
          '&::-webkit-scrollbar-thumb': {
            background: lightTheme.palette.primary.b3,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: lightTheme.palette.primary.b2,
          },
        }}
      >
        <TablePanelBox value={value} index={0} dir={theme.direction}>
          <TableContainer>
            <Table>
              {rdvAVenir &&
                rdvAVenir.map((rdv, i) => (
                  <BodyTable key={i}>
                    <TableRow>
                      <StyledTableCell>
                        {moment(rdv.dateDebut)
                          .tz('Europe/Paris')
                          .format('DD/MM/YYYY')}{' '}
                        -{' '}
                        {moment(rdv.dateDebut)
                          .tz('Europe/Paris')
                          .format('HH:mm')}{' '}
                        {type === 'candidat' && rdv.feedbackEntreprise ? (
                          <FontAwesomeIcon
                            sx={{ margin: 5 }}
                            icon={faComment}
                            color={lightTheme.palette.primary.b2}
                          />
                        ) : (
                          type === 'entreprise' &&
                          rdv.feedbackCandidat && (
                            <FontAwesomeIcon
                              icon={faComment}
                              color={lightTheme.palette.primary.b2}
                            />
                          )
                        )}
                      </StyledTableCell>

                      <StyledTableCell sx={{ padding: '2px', width: 10 }}>
                        <IconBox
                          onClick={(e) => {
                            handleOpenEvent(rdv)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            color={lightTheme.palette.primary.b2}
                          />
                        </IconBox>
                      </StyledTableCell>
                    </TableRow>
                  </BodyTable>
                ))}
            </Table>
          </TableContainer>
        </TablePanelBox>
        <TablePanelBox value={value} index={1} dir={theme.direction}>
          <TableContainer>
            <Table>
              {rdvPasse &&
                rdvPasse.map((rdvp, i) => (
                  <BodyTable key={i}>
                    <TableRow>
                      <StyledTableCell>
                        {moment(rdvp.dateDebut)
                          .tz('Europe/Paris')
                          .format('DD/MM/YYYY')}{' '}
                        -{' '}
                        {moment(rdvp.dateDebut)
                          .tz('Europe/Paris')
                          .format('HH:mm')}{' '}
                        {/* /
                        {moment(rdvp.dateFin)
                          .tz('Europe/Paris')
                          .format('HH:mm')}{' '} */}
                        {type === 'candidat' && rdvp.feedbackEntreprise ? (
                          <FontAwesomeIcon
                            sx={{ margin: 5 }}
                            icon={faComment}
                            color={lightTheme.palette.primary.b2}
                          />
                        ) : (
                          type === 'entreprise' &&
                          rdvp.feedbackCandidat && (
                            <FontAwesomeIcon
                              icon={faComment}
                              color={lightTheme.palette.primary.b2}
                            />
                          )
                        )}
                      </StyledTableCell>

                      <StyledTableCell sx={{ padding: '2px', width: 10 }}>
                        <IconBox
                          onClick={(e) => {
                            handleOpenEvent(rdvp)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            color={lightTheme.palette.primary.b2}
                          />
                        </IconBox>
                      </StyledTableCell>
                    </TableRow>
                  </BodyTable>
                ))}
            </Table>
          </TableContainer>
        </TablePanelBox>
      </SwipeableViews>
    </Box>
  )
}
